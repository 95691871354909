import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Content, { HTMLContent } from '../components/Content';
import Seo from '../components/seo';
import PageHeader from '../components/PageHeader';

const ContactPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <div className="columns is-centered">
        <div className="column is-10">
          <PageHeader title={title} />
          <hr />
        </div>
      </div>
      <div className="columns">
        <div className="column is-5 is-offset-1">
          <PageContent className="content " content={content} />
        </div>
        <div className="column is-4 is-offset-1">
          <p className="subtitle sidebar">Kort</p>
          <iframe
            className="card"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            style={{ width: 800, height: 450 }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d745.9968446464101!2d12.421903267683813!3d55.65397094647661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465256d042431433%3A0x699ef819be609ff1!2sPark%20All%C3%A9%20156%2C%202605%20Br%C3%B8ndby!5e0!3m2!1sda!2sdk!4v1679498651984!5m2!1sda!2sdk"
            allowfullscreen
          />
        </div>
      </div>
    </>
  );
};

function ContactPage({ data }) {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <ContactPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
}

export default ContactPage;

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
